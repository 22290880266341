<template>
  <div>
    <a-modal
      :visible="visible"
      title="新增设备台账"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :maskClosable="false"
      @cancel="handleCancel"
      @ok="handleConfirm"
    >
      <a-form :form="dataForm" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }">
        <a-form-item label="编号">
          <a-input
            v-decorator="[
              'number',
              {
                rules: [
                  { required: true, message: '请输入编号' },
                  { max: 32, message: '超出最大长度(32)' },
                ],
              },
            ]"
            :allowClear="true"
          />
        </a-form-item>
        <a-form-item label="名称">
          <a-input
            v-decorator="[
              'name',
              {
                rules: [
                  { required: true, message: '请输入名称' },
                  { max: 64, message: '超出最大长度(64)' },
                ],
              },
            ]"
            :allowClear="true"
          />
        </a-form-item>
        <a-form-item label="设备类型">
          <EquipmentCategorySelect v-decorator="['equipment_category']" />
        </a-form-item>
        <a-form-item label="品牌">
          <a-input v-decorator="['brand', { rules: [{ max: 64, message: '超出最大长度(64)' }] }]" :allowClear="true" />
        </a-form-item>
        <a-form-item label="规格">
          <a-input v-decorator="['spec', { rules: [{ max: 64, message: '超出最大长度(64)' }] }]" :allowClear="true" />
        </a-form-item>
        <a-form-item label="备注">
          <a-textarea
            v-decorator="['remark', { rules: [{ max: 256, message: '超出最大长度(256)' }] }]"
            :rows="3"
            :allowClear="true"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { equipmentCreate, getEquipmentNumber } from "@/api/equipment";

export default {
  components: {
    EquipmentCategorySelect: () => import("@/components/EquipmentCategorySelect"),
  },
  props: ["visible"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      confirmLoading: false,
      dataForm: null,
    };
  },
  methods: {
    handleConfirm() {
      this.dataForm.validateFields((error, values) => {
        if (error === null) {
          this.confirmLoading = true;
          equipmentCreate(values)
            .then((data) => {
              this.$emit("create", data);
              this.$message.success("新增成功");
              this.handleCancel();
            })
            .finally(() => {
              this.confirmLoading = false;
            });
        }
      });
    },
    handleCancel() {
      this.$emit("cancel", false);
    },
  },
  mounted() {
    this.dataForm = this.$form.createForm(this);
  },
  watch: {
    visible(status) {
      if (status) {
        getEquipmentNumber().then((data) => {
          this.dataForm.setFieldsValue(data);
        });
      }
    },
  },
};
</script>

<style scoped></style>
